/* RESET (maybe move to a separate css file)*/


/* Helpers */

*,
*:before,
*:after {
    box-sizing: border-box;
}

* img {
    max-width: 100%;
    height: auto;
}

a:link {
    -webkit-tap-highlight-color: transparent;
}

.inline-block {
    display: inline-block;
}

.w-10 {width:10%; }
.w-20 {width:20%; }
.w-30 {width:30%; }
.w-40 {width:40%; }
.w-50 {width:50%; }
.w-60 {width:60%; }
.w-70 {width:70%; }
.w-80 {width:80%; }
.w-90 {width:90%; }
.w-100 {width:100%; }

/* Slick carousel -- remove blue outline on focus */
.slick-list,
.slick-slide,
.slick-slide a,
.slick-slide:focus {
    outline: none;
    box-shadow: none;
}

/*Remove blue line on input focus*/

input:focus {
    outline: none;
}

.group:before,
.group:after,
.cf:before,
.cf:after {
    content: "";
    display: table;
}

.group:after,
.cf:after {
    clear: both;
}

.pull-left {
    float: left!important;
}

.pull-right {
    float: right!important;
}

.t-left {
    text-align: left!important;
}

.t-center {
    text-align: center!important;
}

.t-right {
    text-align: right!important;
}

.t-line-through {
    text-decoration: line-through!important;
}

.t-italic {
    font-style: italic!important;
}

.uppercase {
    text-transform: uppercase!important;
}

.lowercase {
    text-transform: lowercase!important;
}

.capitalize {
    text-transform: capitalize!important;
}

.small {
    font-size: 88%!important;
}

.row {
    width: 100%;
    display: block;
}

.currency:before {
    content: "$";
}

.invisible {
    visibility: hidden;
}

.disabled {
    pointer-events: none;
}

.hidden {
    display: none!important;
}

/*No text selection on focus, hover, etc*/
.nts {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

/*remove blue line on button*/
.btn,
.btn:active,
.btn:focus {
    outline: 0;
}

@media only screen and (max-width: 800px) {
    .hidden-mobile {
        display: none;
    }
}

.unstyled-list {
    padding: 0!important;
    margin: .8rem 0;
    list-style: none!important
    ;
}

.inline-list li {
    display: inline-block;
}

.in-between {
    display: inline-block;
    margin: 0 5px;
}


/*Reusable components*/


/* Grid */
.row { width: 100%; }

.row:before,
.row:after{
	content: "";
	display: table;
}
.row:after{
	clear: both;
}

.col{
	float: left;
	padding: 0 1rem;
}
.col:first-child{
	padding-left: 0;
}
.col:last-child{
	padding-right: 0;
}


/*Basic grid layout for checkout page*/

.col-full {
    width: 100%;
}

.col-half {
    width: 50%;
}

.col-secondary {
    width: 38%;
    margin-right: 2%;
}

.col-primary {
    width: 60%;
}

/*Grid layout for cart page*/

.col-twelve    { width : 100%; }
.col-eleven    { width : 50%; }
.col-ten  { width : 33.333333%; }
.col-nine   { width : 25%; }
.col-eight   { width : 20%; }
.col-seven    { width : 16.6666667%; }
.col-six  { width : 14.28571429%; }
.col-five  { width : 12.5%; }
.col-four   { width : 11.11111111%; }
.col-three    { width : 10%; }
.col-two { width : 9.09090909%; }
.col-one { width : 8.33333333%; }

.col.col-push{
	float: right;
	margin-left: 0;
}
.col.col-pull{
	float: left;
	margin-right: 0;
}

.content-box {
    margin: 0 0 4% 0;
}


/*Buttons (foundation)*/

.btn {
    border: 0;
    background: transparent;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    font-weight: bold;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-transition: .18s ease-in-out;
    transition: .18s ease-in-out;
}

.btn--disabled {
    pointer-events: none;
    cursor: not-allowed;
}

.btn--block {
    width: 100%;
    display: block;
}


/* MODALS */

.modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 12000;
}

.modal--body {
    position: relative;
}

.modal--close {
    display: inline;
    position: absolute;
    text-decoration: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


/* ******************* */


/* LAYOUT */


/* ******************* */

.container {
    margin: 0 auto;
    position: relative;
}


/*Stack any floating columns on small screens*/
@media only screen and (max-width: 800px) {
    .col {
        width: 100%!important;
    }
    .w-sm-10 {width:10%; }
    .w-sm-20 {width:20%; }
    .w-sm-30 {width:30%; }
    .w-sm-40 {width:40%; }
    .w-sm-50 {width:50%; }
    .w-sm-60 {width:60%; }
    .w-sm-70 {width:70%; }
    .w-sm-80 {width:80%; }
    .w-sm-90 {width:90%; }
    .w-sm-100 {width:100%; }
}

/* Fluid page width */

@media only screen and (min-width:801px) {
    .container {
        max-width: 100%;
    }
    .lg-pull-left {
        float: left!important;
    }
    .lg-pull-right {
        float: right!important;
    }
}

@media only screen and (min-width:1420px) {
    .container {
        max-width: 90%;
        max-width: 1420px;
    }
}


/* ******************* */


/* #TOPHAT */


/* ******************* */

.site-tophat {
    float: none;
    width: 100%;
    height: 30px;
    text-align: center;
    overflow: hidden;
    font-size: .8rem;
}

.site-tophat a,
.site-tophat span {
    display: inline;
}

.site-tophat li,
.site-tophat a,
.site-tophat span {
    font-size: inherit;
}

.site-tophat .offers li {
    line-height: inherit;
    display: block;
}

.site-tophat a:last-child,
.site-tophat span:last-child {
    padding-right: 0;
}

.site-tophat a:hover {
    text-decoration: underline;
}

.site-tophat .tophat-item {
    display: inline-block;
    height: inherit;
    float: left;
    margin-right: 2%;
}

.site-tophat .top-userstuff a {
    padding-right: 0;
}

.site-tophat .top-userstuff > .divider {
    padding: 0;
}

.site-tophat .tophat-item:last-child {
    margin-right: 0;
    margin-left: auto;
    float: right;
}

/*.da_secure .site-tophat .tophat-item:last-child {
    float: left;
}*/

/* ******************* */


/* BRAND LOGOS */


/* ******************* */


/* cherokee */

.brand_8900 {
    background-image: url("/meta/pie/0004/0004/0777/logos/cherokee_color.png");
}


/* runway by cherokee */

.brand_89101 {
    background-image: url("/meta/pie/0004/0004/0777/logos/runway_color.png");
}

/* sapphire */

.brand_89107 {
    background-image: url("/meta/pie/0004/0004/0777/logos/sapphire_color.png");
}


/* swiftwick */

.brand_89112 {
    background-image: url("/meta/pie/0004/0004/0777/logos/swiftwick_color.png");
}


/* step-inz */

.brand_9005 {
    background-image: url("/meta/pie/0004/0004/0777/logos/stepinz_color.png");
}


/* medman */

.brand_8905 {
    background-image: url("/meta/pie/0004/0004/0777/logos/medman_color.png");
}


/* dickies */

.brand_8901 {
    background-image: url("/meta/pie/0004/0004/0777/logos/dickies_color.png");
}

/* dickies chef */

.brand_89115 {
    background-image: url("/meta/pie/0004/0004/0777/logos/dickies_chef_color.png");
}


/* code happy */

.brand_89106 {
    background-image: url("/meta/pie/0004/0004/0777/logos/codehappy_color.png");
}


/* ryka */

.brand_8935 {
    background-image: url("/meta/pie/0004/0004/0777/logos/ryka_color.png");
}


/* heartsoul */

.brand_89103 {
    background-image: url("/meta/pie/0004/0004/0777/logos/heartsoul_color.png");
}


/* laforst */

.brand_89104 {
    background-image: url("/meta/pie/0004/0004/0777/logos/laforst_color.png");
}


/* mozo */

.brand_9003 {
    background-image: url("/meta/pie/0004/0004/0777/logos/mozo_temp.png");
}


/* workwear */

.brand_8902 {
    background-image: url("/meta/pie/0004/0004/0777/logos/workwear_color.png");
}
.brand_8936 {
    /*brand is no longer active*/
    display: none!important;
}


/* skechers */

.brand_8952 {
    background-image: url("/meta/pie/0004/0004/0777/logos/skechers_color.png");
}


/* littman */

.brand_8959 {
    background-image: url("/meta/pie/0004/0004/0777/logos/littmann_color.png");
}


/* classroom uniforms */

.brand_8908 {
    background-image: url("/meta/pie/0004/0004/0777/logos/classroom_color.png");
}

/* real school */
.brand_89110 {
    background-image: url("/meta/pie/0004/0004/0777/logos/realschool_color.png");
}


/* certainty */

.brand_8926 {
    background-image: url("/meta/pie/0004/0004/0777/logos/certainty_color.png");
}


/* k-swiss */

.brand_8945 {
    background-image: url("/meta/pie/0004/0004/0777/logos/kswiss_color.png");
}


/*anywear*/

.brand_8940 {
    background-image: url("/meta/pie/0004/0004/0777/logos/anywear_color.png");
}


/*boojee*/

.brand_8922 {
    background-image: url("/meta/pie/0004/0004/0777/logos/boojee_color.png");
}


/*Avia*/

.brand_8919 {
    background-image: url("/meta/pie/0004/0004/0777/logos/avia_color.png");
}


/*Asics*/

.brand_9006 {
    background-image: url("/meta/pie/0004/0004/0777/logos/asics_color.png");
}


/*ADC*/

.brand_8920 {
    background-image: url("/meta/pie/0004/0004/0777/logos/adc_color.png");
}


/*Dr. Scholl's*/

.brand_9007 {
    background-image: url("/meta/pie/0004/0004/0777/logos/drscholls_color.png");
}


/*Roxo*/

.brand_9008 {
    background-image: url("/meta/pie/0004/0004/0777/logos/roxo_color.png");
}


/*Sloggers*/

.brand_89108 {
    background-image: url("/meta/pie/0004/0004/0777/logos/sloggers_color.png");
}


/*Therafirm*/

.brand_89105 {
    background-image: url("/meta/pie/0004/0004/0777/logos/therafirm_color.png");
}


/*Fila*/

.brand_89109 {
    background-image: url("/meta/pie/0004/0004/0777/logos/fila_color.png");
}


/* scrub HQ*/

.brand_8906 {
    display: none!important;
    /*background-image: url("/meta/pie/0004/0004/0777/logos/cherokee_color.png");*/
}


/* tooniforms*/

.brand_8904 {
    background-image: url("/meta/pie/0004/0004/0777/logos/tooniforms_color.png");
}

/* reebok */

.brand_89114 {
    background-image: url("/meta/pie/0004/0004/0777/logos/reebok_color.png");
}


/* celeste stein */

.brand_89113 {
    background-image: url("/meta/pie/0004/0004/0777/logos/celestestein_color.png");
}

/* careisma */
.brand_8929 {
    background-image: url("/meta/pie/0004/0004/0777/logos/careisma_color.png");
}

/* sanibel */
.brand_89117 {
    background-image: url("/meta/pie/0004/0004/0777/logos/sanibel_color.png");
}

/* scrubstar */
.brand_8917 {
  background-image: url("/meta/pie/0004/0004/0777/logos/scrubstar_color.png");
}

/* scrubstar.ca */
.brand_8925 {
  background-image: url("/meta/pie/0004/0004/0777/logos/scrubstar_ca_color.png");
}

/* MDF */
.brand_89121 {
  background-image: url("/meta/pie/0004/0004/0777/logos/mdf_color.png");
}

.brand_89122 {
  background-image: url("/meta/pie/0004/0004/0777/logos/infinity_footwear_color.png");
}

/* Megan's mobile quick menu */

.mobile-quick-menu {
    display: none;
    float: left;
    width: 100%;
    margin: -2.2% 0 0 0;
}

.mobile-quick-menu ul {
    padding: 0;
    margin: 0;
    width: 100%;
    float: left;
    clear: both;
}

.mobile-quick-menu ul li {
    float: left;
    clear: both;
    width: 100%;
    border-bottom: 1px solid #CCC;
}

.mobile-quick-menu ul li a {
    display: block;
    width: 100%;
    line-height: 36px;
    float: left;
    clear: both;
    text-indent: 16px;
    color: #000;
    text-align: left;
    text-decoration: none;
    font-size: .9rem;
}


/* Megan's toggle menu */

.togglePanelBtn {
    margin: 0;
    position: fixed;
    left: 0;
    height: 30px;
    line-height: 30px;
    font-size: .725rem;
    color: #eee;
    padding: 0 10px;
    background-color: #444;
}

.togglePanelBtn:hover {
    color: #fff;
    background-color: #313131;
}

.togglePanel {
    float: left;
    position: fixed;
    top: 30px;
    left: 0px;
    background-color: white;
    z-index: 50;
    width: 180px;
    text-align: left;
    padding: 10px;
    line-height: 25px;
    box-shadow: 3px 3px 5px rgba(33, 33, 33, .12);
}

.togglePanel span,
.togglePanel h2 {
    float: left;
    clear: both;
}

.togglePanel span,
.togglePanelBtn {
    cursor: pointer;
    font-size: .7rem;
}

.togglePanel h2 {
    font-size: .85rem;
    border-bottom: 1px solid;
}

#optToggle span {
    color: #626262;
}

#optToggle span:hover {
    color: #414141;
    text-decoration: underline;
}

/* ******************* */


/* #CATEGORY CONTENT*/


/* ******************* */


/*  Category header */


/*Category title*/


/* Filter & Sorting */

#filters {
    float: left;
    display: -webkit-flex;
    -webkit-align-items: center;
    -webkit-flex-wrap: wrap;
    display: -ms-flex;
    -ms-align-items: center;
    -ms-flex-wrap: wrap;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

#sorting_box {
    float: right;
    margin-left: auto;
}

@media only screen and (min-width: 1001px) and (max-width: 1200px) {
    .filter,
    .sort {
        width: 90px;
    }
    .options {
        width: 90px;
    }
}

@media only screen and (min-width: 801px) and (max-width: 1000px) {
    .toolbox .handle {
        margin-right: 0;
    }
    .filter,
    .sort {
        margin-right: 2px;
    }
}

@media only screen and (max-width: 800px) {
    #filters,
    #sorting_box {
        float: left;
    }
    #filters {
        display: none;
    }
    /*Show sorting only*/
    .sort,
    .options {
        width: 200px;
    }
}


/*#promo a { letter-spacing: .3pt; }
#promo { text-align:center; padding:25px 0; font-size:.9rem;}
#promo .cta { text-decoration:underline;}
#promo .highlight { font-weight:bold;}

#content table { border-collapse:collapse; }
#content td, #content th { padding:5px; }*/


/* reviews */


/* layout */

.rating {
    float: left;
    margin-right: 20px;
}

.rating:after {
    content: '';
    display: table;
    clear: both;
}

.rating > .star {
    display: inline-block;
    position: relative;
    float: right;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
}

.rating > .star:before,
.rating > .star:after {
    position: absolute;
    left: 0;
    text-indent: 0;
    width: 100%;
    text-align: center;
}

.rating.active > .star:hover:before,
.rating.active > .star:hover ~ .star:before,
.rating > .star.full:before {
    display: inline;
}


/* icons */

.rating > .star:before {
    content: "\2606";
}

.rating.active > .star:hover:before,
.rating.active > .star:hover ~ .star:before,
.rating > .star.full:before,
.rating > .star.half:after {
    content: "\2605";
}

.rating > .star.half:before {}

.rating > .star.half:after {
    overflow: hidden;
}


/* colors & interaction; use class .active to allow rollovers */

.rating.active > .star:hover:before,
.rating.active > .star:hover ~ .star:before,
.rating.active > .star:hover:before,
.rating.active > .star:hover ~ .star:before {
    -webkit-transition: all .5s;
    transition: all .5s;
    text-shadow: 1px 1px 2px rgba(1, 1, 1, .2);
}

.rating.active > .star {
    cursor: pointer;
}


/* sizing */

.rating {
    width: 135px;
    text-align: center;
}

.rating > .star {
    font-size: 25px;
    line-height: 100%;
    height: 25px;
    width: 27px;
}

.rating,
.review_box {
    line-height: 30px;
}

.rating > .star.half:after {
    width: 10px;
    left: 3px
}


/* content pages */

#pageCopy,
#content .generic_content {
    width: 100%;
    max-width: 650px;
    margin: 0 auto;
    font-family: 'Open Sans', sans-serif;
}

/* *******************
	product page
********************** */


/*Top breadcrumbs*/

#breadcrumbs {

    max-width: 500px;
    width: 100%;
}

#product .color_grid {
    width: auto;
}


/*Product*/

#product_images,
#product_info {
    float: left;
}


/*Product info*/

#product_info {
    width: 40%;
    margin-left: 1%;
    margin-top: -4%;
}


/*Product image*/

#product_images {
    width: 59%;
}

